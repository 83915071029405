body {
	background: #FFFFFF !important;
	font-family: 'Montserrat', sans-serif;
}

h1 {
	font-family: 'Josefin Sans', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
	color: #3E4460 !important;
}

#AdminActionBar button:first-child {
	border-top-left-radius: 1rem;
	border-bottom-left-radius: 1rem;
}

#AdminActionBar button:last-child {
	border-top-right-radius: 1rem;
	border-bottom-right-radius: 1rem;
}

/**
 * zogen Template
 */
.bg-zogen-primary { background-color: #eae8db !important; }
.bg-zogen-secondary { background-color: #bc953f !important; }
.bg-zogen-tertiary { background-color: #f1f0e9 !important; }
.bg-zogen-quaternary { background-color: #834424 !important; }
.bg-zogen-blue { background-color: #3E4460 !important; }

.border-zogen-primary { border-color: #eae8db !important; }
.border-zogen-secondary { border-color: #bc953f !important; }
.border-zogen-tertiary { border-color: #f1f0e9 !important; }
.border-zogen-quaternary { border-color: #834424 !important; }

.text-zogen-primary { color: #eae8db !important; }
.text-zogen-secondary { color: #bc953f !important; }
.text-zogen-tertiary { color: #f1f0e9 !important; }
.text-zogen-quaternary { color: #834424 !important; }
.text-zogen-beige { color: #ECEADD !important; }

.active.row-zogen-secondary { color: white !important; }
.active td.text-zogen-secondary,
.hover\:bg-zogen-secondary:hover td.text-zogen-secondary { color: #eae8db !important; }

.active.row-zogen-primary,
.hover\:bg-zogen-primary:hover { background-color: #eae8db !important; }
.active.row-zogen-secondary,
.hover\:bg-zogen-secondary:hover { background-color: #bc953f !important; }
.active.row-zogen-tertiary,
.hover\:bg-zogen-tertiary:hover { background-color: #f1f0e9 !important; }
.active.row-zogen-quaternary,
.hover\:bg-zogen-quaternary:hover { background-color: #834424 !important; }

.hover\:text-zogen-primary:hover { color: #eae8db !important; }
.hover\:text-zogen-secondary:hover { color: #bc953f !important; }
.hover\:text-zogen-tertiary:hover { color: #f1f0e9 !important; }
.hover\:text-zogen-quaternary:hover { color: #834424 !important; }

.order-first .activity_notes:after,
.order-last .activity_notes:before {
	border: solid transparent;
	border-bottom-color: #f1f0e9;
	border-width: 10px;
	bottom: 100%;
	box-sizing: border-box;
	content: " ";
	height: 0;
	margin-left: -10px;
	pointer-events: none;
	position: absolute;
	top: -10px;
	transform: rotate(45deg);
	width: 0;
}

.order-first .activity_notes:after {
	left: 0px;
}

.order-last .activity_notes:before {
	--tw-border-opacity: 1;
    border-bottom-color: rgb(244 244 245 / var(--tw-border-opacity));
	right: -10px;
	top: -10px;
	transform: rotate(-45deg);
}

.zogen-button {
	background-color: #824026 !important;
	color: #ECEADD !important;
	border: 2px solid #834424 !important;
}

.zogen-button-cancel {
	background-color: rgb(250 250 250) !important;
	color: rgb(50 50 50) !important;
	border: 2px solid rgb(50 50 50) !important;
}

.zogen-button-trash {
	background-color: rgb(254 202 202) !important;
	color: rgb(153 27 27) !important;
	border: 2px solid rgb(220 38 38) !important;
}

.disabled\:zogen-button:disabled {
	background-color: rgb(228 228 231) !important;
	color: rgb(161 161 170) !important;
	border: 2px solid rgb(161 161 170) !important;
}

.zogen-separator {
	cursor: default;
	overflow: hidden;
	text-align: center;
}

.zogen-separator:before,
.zogen-separator:after {
	background-color: rgb(188, 149, 63, 1);
	/*background-color: rgb(229, 231, 235);*/
	content: "";
	display: inline-block;
	height: 1px;
	position: relative;
	vertical-align: middle;
	width: 50%;
}

.zogen-separator:before {
	right: 1rem;
	margin-left: -50%;
}

.zogen-separator:after {
	left: 1rem;
	margin-right: -50%;
}

.zogen-separator-alt.zogen-separator:before, 
.zogen-separator-alt.zogen-separator:after {
	background-color: #834424;
}

@media (min-width: 768px) {
	.md\:border-b-0 { border-bottom-width: 0px; }
	.md\:border-r { border-right-width: 1px; }
	.md\:flex { display: flex; }
	.md\:flex-col { flex-direction: column; }
	.md\:gap-0 { gap: 0px; }
	.md\:grid { display: grid; }
	.md\:grid-cols-3 { grid-template-columns: repeat(3, minmax(0, 1fr)); }
	.md\:mb-0 { margin-bottom: 0px; }
	.md\:p-10 { padding: 2.5rem; }
}

@media (min-width: 1024px) {
	.lg\:bg-zogen-primary { background-color: #eae8db !important; }
	.lg\:bg-zogen-secondary { background-color: #bc953f !important; }
	.lg\:bg-zogen-tertiary { background-color: #f1f0e9 !important; }
	.lg\:bg-zogen-quaternary { background-color: #834424 !important; }

	.lg\:text-zogen-primary { color: #eae8db !important; }
	.lg\:text-zogen-secondary { color: #bc953f !important; }
	.lg\:text-zogen-tertiary { color: #f1f0e9 !important; }
	.lg\:text-zogen-quaternary { color: #834424 !important; }

	.lg\:hover\:bg-zogen-secondary:hover td.text-zogen-secondary { color: #eae8db !important; }

	.lg\:hover\:bg-zogen-primary:hover { background-color: #eae8db !important; }
	.lg\:hover\:bg-zogen-secondary:hover { background-color: #bc953f !important; }
	.lg\:hover\:bg-zogen-tertiary:hover { background-color: #f1f0e9 !important; }
	.lg\:hover\:bg-zogen-quaternary:hover { background-color: #834424 !important; }

	.lg\:hover\:text-zogen-primary:hover { color: #eae8db !important; }
	.lg\:hover\:text-zogen-secondary:hover { color: #bc953f !important; }
	.lg\:hover\:text-zogen-tertiary:hover { color: #f1f0e9 !important; }
	.lg\:hover\:text-zogen-quaternary:hover { color: #834424 !important; }
}

div:where(.swal2-container) button:where(.swal2-styled):where(.swal2-confirm) {
	background-color:#7066e0!important;
}

.searchHidden {
	display: none !important;
}

.dropdown {
	border: 1px solid #ccc;
	width: 200px;
	position: relative;
}

.dropdown-input {
	width: 100%;
	padding: 10px;
	box-sizing: border-box;
}

.dropdown-list {
	background: white;
	border-top: none;
	border: 1px solid #ccc;
	display: none; /* Hidden by default */
	list-style: none;
	margin: 0;
	max-height: 200px; /* Limit height */
	overflow-y: auto;
	padding: 0;
	position: absolute;
	width: 100%;
	z-index: 1;
}

.dropdown-list li {
	padding: 10px;
	cursor: pointer;
}

.dropdown-list li:hover {
	background-color: #eee;
}

.dropdown-list.open {
	display: block; /* Show when open */
}